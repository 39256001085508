import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import unionLogo from "../../images/common/Union.svg";
import Facebook from "../../images/Footer/facebook.svg";
import Twitter from "../../images/Footer/twitter.svg";
import Instagram from "../../images/Footer/instagram.svg";
import OssLogo from "../../images/Footer/oss-logo.svg";
import "./styles.scss";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <Container
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Row style={{ width: "100%" }} className="footer-for-desktop">
          <Col className="footer-col-tablet" lg={6} xl={6} md={5} sm={4} xs={6}>
            <div className="footer-logo-wrapper">
              <div className="logo">
                <img className="logo-image" src={unionLogo} alt="unionLogo" />
              </div>
              <span className="logo-content">
                {t("Footer.5")}
                <a
                  className="developers-logo"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://os-system.com/"
                >
                  <img className="logo-image" src={OssLogo} alt="Utilitium" />
                </a>
              </span>
            </div>
          </Col>
          <Col className="footer-col-tablet" lg={6} xl={6} md={7} sm={8} xs={6}>
            <div className="footer-right-columns">
              <div className="footer-work-time">
                <div className="work-time-wrapper">
                  <span className="work-time-title">{t("Footer.1")}</span>
                  <span className="work-time-days">{t("Footer.6")}</span>
                  <span className="work-time">9:00 - 18:00</span>
                </div>
                <div className="social-networks">
                  <span className="social-networks-title">{t("Footer.3")}</span>
                  <div className="social-networks-links">
                    <a
                      className="social-network facebook"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.facebook.com/utilitium/"
                    >
                      <img className="logo-image" src={Facebook} alt="Utilitium" />
                    </a>
                    <a
                      className="social-network twitter"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://twitter.com/Utilitium_com"
                    >
                      <img className="logo-image" src={Twitter} alt="Utilitium" />
                    </a>
                    <a
                      className="social-network instagram"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.instagram.com/utilitium/"
                    >
                      <img className="logo-image" src={Instagram} alt="Utilitium" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="footer-departments">
                <div className="sales-department">
                  <span className="sales-department-title">
                    {t("Footer.2")}
                  </span>
                  <a
                    className="sales-department-phone"
                    href="tel:+380952794212"
                  >
                    +38 (095) 279-42-12
                  </a>
                  <a
                    className="sales-department-mail"
                    href="mailto:sale@utilitium.com"
                  >
                    sale@utilitium.com
                  </a>
                </div>
                <div className="support-department">
                  <span className="support-department-title">
                    {t("Footer.4")}
                  </span>
                  <a
                    className="support-department-phone"
                    href="tel:+380504005993"
                  >
                    +38 (050) 400-59-93
                  </a>
                  <a
                    className="support-department-mail"
                    href="mailto:services@utilitium.com"
                  >
                    services@utilitium.com
                  </a>
                </div>
                <div className="social-networks-mobile">
                  <span className="social-networks-title">{t("Footer.3")}</span>
                  <div className="social-networks-links">
                    <a
                      className="social-network facebook"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.facebook.com/utilitium/"
                    >
                      <img className="logo-image" src={Facebook} alt="Utilitium" />
                    </a>
                    <a
                      className="social-network twitter"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://twitter.com/Utilitium_com"
                    >
                      <img className="logo-image" src={Twitter} alt="Utilitium" />
                    </a>
                    <a
                      className="social-network instagram"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.instagram.com/utilitium/"
                    >
                      <img className="logo-image" src={Instagram} alt="Utilitium" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ width: "100%" }} className="footer-for-mobile">
          <Col className="footer-col-mobile" lg={6} xl={6} md={5} sm={4} xs={6}>
            <div className="footer-work-time">
              <div className="work-time-wrapper">
                <span className="work-time-title">{t("Footer.1")}</span>
                <span className="work-time-days">{t("Footer.6")}</span>
                <span className="work-time">9:00 - 18:00</span>
              </div>
            </div>
            <div className="social-networks-mobile">
              <span className="social-networks-title">{t("Footer.3")}</span>
              <div className="social-networks-links">
                <a
                  className="social-network facebook"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/utilitium/"
                >
                  <img className="logo-image" src={Facebook} alt="Utilitium" />
                </a>
                <a
                  className="social-network twitter"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://twitter.com/Utilitium_com"
                >
                  <img className="logo-image" src={Twitter} alt="Utilitium" />
                </a>
                <a
                  className="social-network instagram"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/utilitium/"
                >
                  <img className="logo-image" src={Instagram} alt="Utilitium" />
                </a>
              </div>
            </div>
            <div className="footer-logo-wrapper">
              <div className="logo">
                <img className="logo-image" src={unionLogo} alt="unionLogo" />
              </div>
            </div>
          </Col>
          <Col className="footer-col-mobile" lg={6} xl={6} md={7} sm={8} xs={6}>
            <div className="footer-right-columns">
              <div className="footer-departments">
                <div className="sales-department">
                  <span className="sales-department-title">
                    {t("Footer.2")}
                  </span>
                  <a
                    className="sales-department-phone"
                    href="tel:+380952794212"
                  >
                    +38 (095) 279-42-12
                  </a>
                  <a
                    className="sales-department-mail"
                    href="mailto:sale@utilitium.com"
                  >
                    sale@utilitium.com
                  </a>
                </div>
                <div className="support-department">
                  <span className="support-department-title">
                    {t("Footer.4")}
                  </span>
                  <a
                    className="support-department-phone"
                    href="tel:+380504005993"
                  >
                    +38 (050) 400-59-93
                  </a>
                  <a
                    className="support-department-mail"
                    href="mailto:services@utilitium.com"
                  >
                    services@utilitium.com
                  </a>
                </div>
                <span className="logo-content">
                  {t("Footer.5")}
                  <a
                    className="developers-logo"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://os-system.com/"
                  >
                    <img className="logo-image" src={OssLogo} alt="Utilitium" />
                  </a>
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <Row
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
          lg={12}
          xl={12}
          md={12}
          sm={12}
          xs={12}
        >
          <Col
            lg={12}
            xl={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="footer-data">© Utilitium, {new Date().getFullYear()}</span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
