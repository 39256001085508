import React, { useRef } from 'react';
import Container from "react-bootstrap/Container";
import ThemeContext from "../../../contexts/ThemeContext";
import Header from '../../Header';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Loader from '../Loader'
import Footer from '../../Footer';
import './Layout.scss';

export default function Layout({ children, path }) {
  // const [theme, setTheme] = useState("light");
  const pageTop = useRef();
  const formRef = useRef();
  
  const scrollToForm = (context) => {
    if (context.formRef && context.formRef.current) {
      const form = context.formRef.current;
      form.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  };

  const scrollToTop = (context) => {
    if (context.pageTop && context.pageTop.current) {
      const pageMain = context.pageTop.current;
      pageMain.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  };

  const state = {
    // theme: theme,
    formRef: formRef,
    scrollToForm: scrollToForm,
    pageTop: pageTop,
    scrollToTop: scrollToTop

  };
  return (
    <div className='site-container'>
      {/* <Suspense fallback={<Loader />}> */}
      <ThemeContext.Provider value={state}>
        <Header />
        <Container id='main' className={path === "info" ? 'main info' : 'main'}>{children}</Container>
        <Footer />
      </ThemeContext.Provider>
      {/* </Suspense> */}
    </div>
  )
}
