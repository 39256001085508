import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next";
import { usePageContext } from '../../../locales/PageContext';
import Fav from '../../../images/Favicon.svg'

function SEO() {
  const Preview = "https://en.utilitium.com/share-img.png"
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            supportedLanguages
          }
        }
      }
    `
  )
  const { t, i18n } = useTranslation();
  const { lang, originalPath } = usePageContext();
  const host = site.siteMetadata.siteUrl;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={t("Metatags.title")}

      link={[{
        rel: `canonical`,
        href: `${t("Metatags.url")}${originalPath}`,
      },
      {
        rel: 'alternate',
        hrefLang: 'x-default',
        href: `${t("Metatags.url")}${originalPath}`,
      },
      ...site.siteMetadata.supportedLanguages.map(l => {
        const h = t("Metatags.url").replace(/(en|ru|ua)/, l);
        return {
          rel: 'alternate',
          hrefLang: l,
          href: `${h}${originalPath}`,
        }
      })]}

      meta={[
        {
          name: 'description',
          content: t("Metatags.description"),
        },
        {
          name: 'keywords',
          content: t("Metatags.keywords"),
        },
        {
          property: 'og:type',
          content: `website`,
        },
        {
          property: 'og:site_name',
          content: 'utilitium.com',
        },
        {
          property: 'og:url',
          content: t("Metatags.url"),
        },
        {
          property: 'og:title',
          content: t("Metatags.title"),
        },
        {
          property: 'og:description',
          content: t("Metatags.description"),
        },
        {
          property: 'og:locate',
          content: t("Metatags.locate"),
        },
        {
          property: 'og:image',
          content: Preview,
        },
        {
          property: 'og:image:secure_url',
          content: Preview,
        },
        {
          property: 'og:image:alt',
          content: t("Metatags.imageAlt"),
        },
        {
          property: 'og:image:type',
          content: `image/png`,
        },
        {
          property: 'og:image:width',
          content: `968`,
        },
        {
          property: 'og:image:height',
          content: `504`,
        },
        {
          name: 'twitter:card',
          content: `summary_large_image`,
        },
        {
          name: 'twitter:title',
          content: t("Metatags.title"),
        },
        {
          name: 'twitter:description',
          content: t("Metatags.description"),
        },
        {
          name: 'twitter:image:src',
          content: Preview,
        },
        {
          name: 'twitter:domain',
          content: `utilitium.com`,
        },
        {
          name: 'twitter:image:alt',
          content: t("Metatags.imageAlt"),
        },
        {
          name: 'msapplication-TileImage',
          content: Fav,
        }
      ]}
    />
  )
}

export default SEO
